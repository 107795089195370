@import "/src/styles/colors";

.mainWrapper {
  display: flex;
  width: 100vw;
  background-size: cover;
  background-position: center;
  min-height: 100dvh;

  .desktopSidebar {
    // width: 10%;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 20px 5px;
    position: relative;

    .logoSection {
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;
      }
    }

    .pageActionButtons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .menuItemsBordered {
        .item {
          width: 160px;
          height: 40px;
          color: #000;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          font-family: "Montserrat-Medium";
          font-size: 14px;
          cursor: pointer;
          padding: 10px;

          svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .bottomBar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 60%;
      margin-bottom: 20px;

      .links {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;

        a {
          display: flex;
          justify-content: space-between;
          color: #000;
          cursor: pointer;
          font-size: 14px;
          font-family: "Montserrat-Regular";
        }
      }

      .logoutButton {
        cursor: pointer;
      }

      .button {
        margin: 0 auto;
        &:last-child {
          margin-top: 20px;
        }
      }

      .additionalItem {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        border-radius: 2px;
        justify-content: center;
        font-size: 12px;
      }
    }

    .upperSidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .earnMedalsPopup {
    width: 900px;
    height: 600px;
    background-image: linear-gradient(
      to right bottom,
      var(--backgroundColor),
      #420f75,
      #30104f,
      #1e0d2c,
      #050008
    );
    position: absolute;
    top: 200px;
    left: calc(50% - 450px);
    border-radius: 12px;
    padding: 20px;
    z-index: 999999999;

    .header {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      svg {
        cursor: pointer;
      }
    }

    .medalsWrapper {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;

      h1 {
        color: #f8bc01;
        font-family: "Montserrat-Bold";
        font-size: 44px;
      }

      span {
        color: #fff;
        font-size: 16px;
      }

      button {
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
      }
    }
  }

  .languageButton {
    width: 70px;
    height: 62px;
    border-radius: 8px;
    background: #8080801f;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .mobileSidebar {
    display: none;
    position: relative;
    z-index: 999;
    background-color: #fff;
    transition: all 0.3s ease-in-out;

    .menuText {
      width: 200px;
      display: flex;
      margin-right: 20px;
      text-align: right;
      font-family: "Montserrat-Medium";
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      overflow: hidden;
      z-index: 999;

      span {
        height: 50px;
        color: #000;
      }
    }

    .closeMenuIcon {
      display: flex;
      width: 65px;
      float: right;
    }

    .menuItems {
      flex-direction: column;
      margin: 10px 20px;
      border-radius: 28px;
      display: flex;
      align-items: center;
      transition: all 0.1s linear;
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      -o-transition: all 0.1s linear;
      z-index: 999;
      background-color: transparent;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "Montserrat-Medium";
        text-transform: uppercase;

        .label {
          width: 70%;
          min-width: 150px;
          word-wrap: break-word;
        }
      }

      .menuIcon {
        border-radius: 50%;
        padding: 10px;
        min-width: 25px;
        max-width: 25px;
        display: flex;
        align-items: center;
        margin-right: 20px;

        svg {
          width: 30px;
          height: 25px;
        }
      }
    }

    .authMobileIcon,
    .menuOpenMobileFirstIcon {
      background-color: var(--backgroundColor);
      color: #fff;
      position: absolute;
      left: 30px;
      bottom: 35px;
      z-index: 999;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      svg {
        width: 35px;
      }
    }

    .mobileChangeLanguageButton,
    .menuOpenMobileNdIcon {
      background-color: var(--backgroundColor);
      color: #fff;
      position: absolute;
      left: 90px;
      bottom: 35px;
      z-index: 999;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      svg {
        width: 35px;
      }
    }

    .menuOpenMobileRdIcon {
      background-color: var(--backgroundColor);
      color: #fff;
      position: absolute;
      left: 150px;
      bottom: 35px;
      z-index: 999;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      .badge {
        position: absolute;
        right: 6px;
        top: 5px;
      }
    }
  }

  .appWrapper {
    position: relative;
    flex-grow: 1;
    max-width: calc(100dvw - 220px);

    .appHeader {
      min-height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .mobileHeader {
      display: none;
    }

    .purchasingFiltersMobile {
      width: 96%;
      height: 95dvh;
      position: absolute;
      top: 28px;
      left: 7px;
      right: 0px;
      border-radius: 32px;
      background-color: #fff;
      z-index: 9999999999;
      border-radius: 32px;
      border: 1px solid #e3e4e4;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 95%;
        overflow-y: scroll;
      }

      .purchasingFilters {
        padding: 0px 30px;
        margin-top: 20px;
      }

      .header {
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 40px;
      }

      .searchBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .searchInput {
        width: 90%;
        height: 40px;
        display: flex;
        align-items: center;
        display: flex;
        align-content: center;
        transition: width 0.3s ease-in-out;
        border: 1px solid #979797;
        border-radius: 7px;

        svg {
          margin: 0px 10px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          color: #000;
          font-family: "Montserrat-SemiBold";
          font-size: 12px;
          font-style: normal;
          text-transform: capitalize;

          &::placeholder {
            color: #000;
          }
        }
      }

      .applyButton {
        width: 20%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #fff;
        border-radius: 7px;
        margin: 50px auto 20px auto;
        cursor: pointer;

        span {
          color: var(--color);
          text-align: center;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.02px;
        }
      }

      .filterButtons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        margin-top: 20px;

        .clearFiltersButton,
        .applyFilterButton {
          width: 85%;
          padding: 10px;
          border-radius: 7px;
          color: #000;
          text-align: center;
          cursor: pointer;
          text-transform: uppercase;
          border: 1px solid #000;
        }

        .applyFilterButton {
          background-color: #000;
          color: #fff;
        }
        .clearFiltersButton {
          background-color: #fff;
          margin: 10px auto 20px auto;
        }
      }
    }

    .listTabs {
      width: 150px;
      height: 30px;
      display: flex;
      align-items: center;

      div {
        width: 65px;
        height: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .purchasingFiltersDesktop {
      display: flex;
      width: 90%;
      justify-content: center;
      align-items: center;
      height: 30px;

      div {
        margin-right: 0.5%;
      }

      .searchInput {
        width: 120px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #000;
        display: flex;
        align-content: center;
        transition: width 0.3s ease-in-out;

        svg {
          margin: 0px 10px;
        }
        input {
          width: 80%;
          background-color: transparent;
          border: none;
          outline: none;
          color: #000;
          font-family: "Montserrat-SemiBold";
          font-size: 12px;
          font-style: normal;
          text-transform: capitalize;

          &::placeholder {
            color: #000;
          }
        }
      }

      .otherFilters {
        display: flex;
      }
    }
  }

  .appWrapperContent {
    flex-grow: 1;
    position: absolute;
    width: 99%;
    padding: 0px 10px;
    height: calc(100% - 170px);
  }

  .contentContainer {
    width: 95%;
    height: 100%;
    margin-bottom: 50px;
  }

  .mHeader {
    display: none;
  }

  .mobileMenuIcon {
    display: none;
    transition: all 0.2s linear;
  }

  .desktopHeader {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 20px;

    .threePartsHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--color);

      .desktopReferralsIcon {
        cursor: pointer;
      }
      .shownMedals {
        display: flex;
        flex-direction: column;
        color: #000;

        span {
          &:first-child {
            font-size: 10px;
          }

          &:last-child {
            font-size: 30px;
          }
        }
      }

      .smallLabel {
        font-family: "Montserrat-Regular";
        font-size: 10px;
        letter-spacing: 0.5px;
        color: #000;
      }

      .stakedValue {
        font-family: "Montserrat-Regular";
        font-size: 30px;
        letter-spacing: 0.481px;
        color: #000;
      }

      .middleTitle {
        color: #000;
        text-align: center;
        text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
        font-family: "Montserrat-SemiBold";
        font-size: 28px;
        line-height: 28px; /* 100% */
        margin-top: 0px;
      }

      .ledgerHeader {
        display: flex;
        justify-content: space-between;
        min-width: 850px;

        // .lSearch {
        //   margin-right: 5px;
        // }
      }

      .aiHeaderDesktop {
        display: flex;
        justify-content: space-between;
        min-width: 650px;
      }

      .scanIcon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--backgroundColor);
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .manualEnterDataIcon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--backgroundColor);
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .tab {
        width: fit-content;
        padding: 8px 20px;
        border-radius: 8px;
        border: 1px solid #000;
        cursor: pointer;
        font-family: "Montserrat-SemiBold";
        font-size: 12px;
      }

      .lSearch {
        position: relative;

        svg {
          position: absolute;
          top: 10px;
          left: 5px;
        }

        input {
          border: 1px solid #000;
          border-radius: 8px;
          outline: none;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          color: #000;
          font-family: "Montserrat-SemiBold";
          font-size: 12px;
          padding: 8px 20px 8px 25px;

          &::placeholder {
            color: #000;
          }
        }
      }

      .investmentsIcon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 38px;
        font-family: "Montserrat-Regular";
        background-color: var(--backgroundColor);
        margin-bottom: 5px;
      }

      .membershipTabs {
        width: 50%;
        display: flex;
        justify-content: space-between;
        max-width: 650px;

        div {
          width: 200px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          margin-right: 10px;
          font-family: "Montserrat-Medium";
          font-size: 15px;
          cursor: pointer;
        }
      }
    }

    .closeButton {
      display: flex;
      float: right;
      width: 100%;
      justify-content: flex-end;
      cursor: pointer;
    }

    .moreFiltersButton {
      width: 120px;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      border-radius: 5px;
      border: 1px solid #000;
      background-color: #000;
      cursor: pointer;
      transition: height 0.3s ease-in-out;
      font-family: "Montserrat-SemiBold";
      font-size: 12px;
      font-style: normal;
      line-height: 20px;
      color: #fff;
    }

    .rightSideButtons {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cartSecondaryIcon {
        margin-top: 1px;
        height: 48px;
        position: relative;
        cursor: pointer;

        .badge {
          position: absolute;
          right: 7px;
          top: 3px;
        }
      }
    }

    .dinoAsisstantDesktopIcon {
      width: 77px;
      height: 77px;
      background-color: #ff6333;
      display: flex;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;

      div {
        width: 65px !important;
        height: 65px !important;
      }
    }

    .bookmarksIconHeader {
      cursor: pointer;
      margin-right: 10px;
      border: 1px solid #000;
      border-radius: 50%;
      width: 48px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .badge {
        position: absolute;
        right: 7px;
        top: 3px;
      }

      svg {
        width: 43px;
        height: 21px;
      }
    }

    .cartIconHeader {
      position: relative;
      cursor: pointer;

      .badge {
        position: absolute;
        right: 7px;
        top: 3px;
      }
    }

    .enterFullscreenIcon {
      position: absolute;
      right: 150px;
      top: 20px;
      cursor: pointer;
    }

    .icon {
      cursor: pointer;
    }
  }

  .cartIconMobile {
    position: relative;
    margin-right: 10px;

    .badge {
      position: absolute;
      right: 7px !important;
      top: 5px !important;
    }
  }
  .purchasingIconMobile {
    display: none;
  }

  .gamificationHeader {
    width: 100%;
    display: flex;
    align-items: center;

    .shownMedals {
      display: flex;
      flex-direction: column;
      color: #000;

      span {
        &:first-child {
          font-size: 10px;
        }

        &:last-child {
          font-size: 30px;
        }
      }
    }

    .rightPartHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .learnMore {
        height: 32px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding: 0px 10px;
        border: 1px solid #1a7fdc;
        border-radius: 24px;
        color: #1a7fdc;
        font-size: 12px;
        font-family: "Montserrat-Medium";
        cursor: pointer;

        svg {
          margin-left: 5px;
        }
      }
      .desktopReferralsIcon {
        cursor: pointer;
      }
    }

    .gamesRightHeader {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .gamesDesktopReferralsIcon {
        cursor: pointer;
        margin-bottom: 6px;
      }
    }
  }

  .homefeedHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      cursor: pointer;
      svg {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 1180px) {
    .menuItems {
      align-items: flex-start !important;

      .item {
        border-radius: 12px;
        height: 50px;
      }
    }
    .appWrapper {
      width: 100dvw;
      max-width: 100%;
      position: unset !important;
      overflow: scroll;

      &::after {
        display: none;
      }

      .appHeader {
        min-height: 50px;
        position: fixed;
        width: calc(100% - 20px);
        top: 0;
        padding: 0px 10px;
        z-index: 99;
      }
      .appWrapperContent {
        width: 100%;
        padding: 0px;
        top: 0px;
        overflow: auto;

        .contentContainer {
          width: 100%;
        }
      }
    }
    .desktopSidebar,
    .desktopHeader,
    .dHeader {
      display: none;
    }

    .earnMedalsPopup {
      width: 86dvw;
      height: 75dvh;
      top: unset;
      bottom: 20px;
      left: 2%;

      .medalsWrapper {
        height: 85%;
        justify-content: space-between;

        h1 {
          font-size: 28px;
        }
      }
    }

    .mobileSidebar {
      display: block;
    }

    .mHeader {
      display: block;
    }

    .mobileMenuIcon {
      display: flex;
      z-index: 99;
    }
    .purchasingIconMobile {
      display: flex;
      position: absolute;
      right: 15px;
      bottom: 30px;
      z-index: 9999;

      .bookmarksIcon {
        width: 48px;
        height: 48px;
        background-color: var(--backgroundColor);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;

        svg {
          width: 15px;
        }

        .badge {
          position: absolute;
          top: 5px !important;
          right: 4px;
        }
      }

      .searchIconMobile {
        width: 48px;
        height: 48px;
        background-color: var(--backgroundColor);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;

        svg {
          width: 27px;
        }
      }

      .filtersIcon {
        width: 48px;
        height: 48px;
        background-color: var(--backgroundColor);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;

        svg {
          width: 27px;
        }

        .badge {
          position: absolute;
          top: 5px !important;
          right: 4px;
        }
      }
    }

    .investmentsPlusIcon {
      display: flex;
      position: absolute;
      right: 20px;
      bottom: 35px;
      z-index: 999;

      div {
        background-color: var(--backgroundColor);
        color: #fff;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 32px;
        font-family: "Montserrat-Regular";
      }
    }

    .aiMobileMenu {
      .aiOptionsBox {
        position: absolute;
        min-width: 260px;
        height: 80px;
        background-image: linear-gradient(
          to right bottom,
          #ff4317,
          #fc5a1f,
          #f96c28,
          #f77b34,
          #f48941
        );
        right: -280px;
        bottom: 35px;
        z-index: 9999;
        border-radius: 12px;
        opacity: 0;
        visibility: hidden;
        transition:
          right 0.2s linear,
          opacity 0.2s linear,
          visibility 0.2s linear;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: #fff;

        .middleSeperator {
          width: 2px;
          height: 60px;
          background-color: #ffff;
        }

        div {
          display: flex;
          align-items: center;
          width: 44%;

          svg {
            margin-right: 10px;
          }

          span {
            font-family: "Montserrat-SemiBold";
            font-size: 15px;
          }
        }
      }
      .askDinoIcon {
        width: 77px;
        height: 77px;
        background-color: #ff6333;
        display: flex;
        align-items: center;
        position: absolute;
        right: 20px;
        bottom: 35px;
        z-index: 999;
        border-radius: 50%;

        div {
          width: 65px !important;
          height: 60px !important;
        }
      }
    }

    .privateLedgerMobileIcons {
      display: flex;
      justify-content: space-between;
      align-content: center;
      position: absolute;
      right: 20px;
      bottom: 30px;
      z-index: 999;

      .searchIconJournal {
        position: relative;
      }

      .tastePreferencesIcon {
        background-color: var(--backgroundColor);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-right: 10px;
      }

      .manualEnterIcon {
        background-color: var(--backgroundColor);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 10px 0px 15px;
        cursor: pointer;
      }
    }

    .languageButton {
      height: 50px;
    }

    .mHeader {
      margin-top: 80px;
      .icons {
        width: 255px;
        height: 55px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #fff;
        background: #fff;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.33);
        margin: 0 auto;

        div {
          background-color: #f5f5f5;
          padding: 10px;
          border-radius: 50%;
          min-width: 25px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .appWrapperContent {
      // height: calc(100% - 90px) !important;
      height: 100% !important;
    }

    .fullHeight {
      height: 98dvh !important;
    }
  }
}

.onlyInChBox {
  width: 600px;
  min-height: 45px;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 330px);
  z-index: 999;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;

  .leftPart {
    display: flex;
    align-items: center;
    max-width: 80%;

    svg {
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  h3 {
    font-size: 16px;
    margin: 0px;
    font-family: "Montserrat-SemiBold";
  }
  span {
    font-size: 14px;
  }

  .closeButton {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #000;
    font-family: "Montserrat-SemiBold";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
  }
  @media screen and (max-width: 1180px) {
    width: 80dvw;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, -50%);

    h3 {
      font-size: 12px;
    }

    span {
      font-size: 10px;
    }
  }
}

.footer {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0px;
  color: #fff;
  background-color: #000;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftPart,
  .closeIcon {
    display: flex;
    align-items: center;
  }

  .leftPart {
    justify-content: space-between;
    width: 50%;
    font-size: 12px;

    div {
      width: 25%;
      cursor: pointer;
      text-align: center;

      span {
        font-size: 12px !important;
        font-family: "Montserrat-Regular";
      }

      &:hover {
        color: #ff6333;
      }
    }

    .closeIcon {
      justify-content: flex-start;
      padding-left: 20px;
      width: 10% !important;
    }
  }

  .rightPart {
    width: 180px;
    height: 100%;
    background-color: #ff6333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    font-family: "Montserrat-Regular";

    svg {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 1180px) {
    .leftPart {
      width: 65%;
      font-size: 10px !important;

      div span {
        font-size: 10px !important;
      }
    }

    .rightPart {
      font-size: 10px;
    }

    .closeIcon {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 400px) {
    .leftPart {
      width: 49%;
    }

    .rightPart {
      width: 150px;
    }
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}
