.wrapper {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;

  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    top: 0;
    position: sticky;
    z-index: 999;
    padding-top: 10px;

    svg {
      width: 32px;
    }

    .backButtonContainer {
      display: flex;
      align-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    .profileIlustration {
      svg {
        width: 100px;
        height: 100px;
      }
    }

    span {
      font-size: 14px;
    }

    .twoStepsProfile {
      display: flex;
      margin-bottom: 20px;

      h3 {
        width: 150px;
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        text-align: center;
        margin: 20px auto;
        min-height: 60px;
        display: flex;
        align-items: center;
      }
      .step {
        &:first-child {
          margin-right: 10px;
        }

        .card {
          width: 120px;
          height: 240px;
          border: 1px solid #000;
          border-radius: 12px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;

          svg {
            width: 100%;
          }

          .item {
            width: 100%;
            height: 40px;
            padding: 0 15px;
            border-radius: 12px;
            position: relative;

            .bodyValue,
            .aromaValue,
            .flavorValue,
            .acidityValue,
            .sweetnessValue {
              top: 0px;
              span {
                margin-left: 10px;
              }
            }

            .bodyValue {
              position: absolute;
              left: 50%;
              display: flex;
              align-items: center;
            }

            .bodyLine {
              position: absolute;
              bottom: -8px;
              left: 40%;
              z-index: 1;
            }

            .aromaValue {
              position: absolute;
              left: 32%;
              display: flex;
              align-items: center;
            }

            .aromaLine {
              position: absolute;
              bottom: -9px;
              left: 39%;
              z-index: 1;
            }

            .flavorValue {
              position: absolute;
              top: 5px;
              left: 68%;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;

              span {
                margin-right: 10px;
              }
            }

            .flavorLine {
              position: absolute;
              bottom: -20px;
              left: 59%;
              z-index: 1;
            }

            .acidityValue {
              position: absolute;
              left: 50%;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              top: 15px;

              span {
                margin-right: 10px;
              }
            }

            .acidityLine {
              position: absolute;
              bottom: -31px;
              left: 21%;
              z-index: 1;
            }

            .sweetnessValue {
              position: absolute;
              left: 13%;
              display: flex;
              align-items: center;
              top: 26px;
            }

            .dotBox {
              width: 28px;
              height: 16px;
              background-color: var(--color, #b7bdc0);
              border-radius: 2px;
              position: relative;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 10px;
                height: 10px;
                circle {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }

    h3 {
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
    }
    .yesAndNoProfile {
      width: 330px;
      height: 155px;
      border-radius: 12px;
      border: 1px solid #000;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .yesAndNoData {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .pros {
          margin-right: 10px;
          align-items: flex-end;
        }

        .pros,
        .cons {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .prosOption,
          .consOption {
            border-radius: 24px;
            width: fit-content;
            height: 34px;
            font-size: 12px;
            font-family: "Montserrat-SemiBold";
            padding: 0px 5px;
            margin-bottom: 10px;

            svg {
              margin: 0px 5px;
            }

            &:last-child {
              margin-bottom: 0px;
            }
          }
          .prosOption {
            color: #49bd36;
            background-color: #deffd6;
            border: 1px solid #49bd36;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
          }

          .consOption {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            color: #fe730d;
            background-color: #fff9d8;
            border: 1px solid #fe730d;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;
  }
}
