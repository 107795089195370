.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .replySeperator {
    background-color: #e8eaef;
    width: 80%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 5px 20px;
    margin: 20px 0px 40px 0px;

    svg {
      width: 24px;
    }

    span {
      font-size: 12px;
    }
  }

  .chatTimeline {
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    .question {
      width: 100%;

      .qCard {
        width: 90%;
        min-height: 95px;
        background-color: #f2e6ff80;
        border-radius: 12px;
        border: 1px solid var(--color);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: relative;
        margin-top: 40px;
        text-align: center;
        span {
          padding: 0px 20px;
          &:first-child {
            font-size: 16px;
            text-align: left;
          }
        }
      }
    }

    .loader {
      width: 100%;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .answer {
      width: 80%;
      min-height: 95px;
      background-color: #f2e6ff80;
      border: 1px solid var(--color);
      border-radius: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 40px;
      padding: 20px;
      font-size: 16px;

      .answerTouchLine {
        position: absolute;
        left: -21px;
        top: -43px;
      }

      .replyTouchLine {
        position: absolute;
        left: -21px;
        bottom: -43px;
      }

      span {
        margin-bottom: 20px;
      }

      .colored {
        color: #ff6333;
        font-family: "Montserrat-Medium";
        margin-bottom: 5px;
      }
    }

    .images {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      overflow-x: scroll;

      .lastProductCard {
        width: 180px;
        height: 210px;
        border: 1px solid rgba(151, 151, 151, 0.2078431373);
        margin: 5px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 15px;
        position: relative;
        background-color: #fff;
        box-sizing: border-box;
        flex: 0 0 auto;
        justify-content: space-around;

        .colored {
          font-family: "Montserrat-SemiBold";
          font-size: 12px;
        }
        span {
          font-size: 11px;
        }

        .leftContent {
          margin-top: -10px;
        }
      }

      .mobileProduct {
        width: 180px;
        height: 210px;
        border: 1px solid rgba(151, 151, 151, 0.2078431373);
        margin: 5px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 10px 15px 0px 15px;
        position: relative;
        background-color: #fff;
        box-sizing: border-box;
        flex: 0 0 auto;

        .leftContent {
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .colored {
            font-family: "Montserrat-SemiBold";
            font-size: 12px;
          }
          span {
            font-size: 11px;
          }
        }

        .rightContent {
          width: 30%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          img {
            height: 150px;
            object-fit: contain;
          }

          .specialProductImage {
            height: 280px;
          }
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      div {
        display: flex;
        align-items: center;
        cursor: pointer;

        div {
          margin-right: 5px;
        }
      }

      .rightSideButtons {
        div {
          height: 28px;
          border: 1.8px solid #000;
          border-radius: 8px;
          padding: 0px 10px;
        }
      }
    }
  }

  .replyBox {
    width: 80%;
    margin-top: 37px;
    display: flex;
    align-items: center;
    position: relative;
    textarea {
      height: 100%;
      min-height: 95px;
      background-color: #f2e6ff80;
      border: 1px solid var(--color);
      border-radius: 8px;
      resize: none;
      outline: none;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      padding: 0px 5px;
    }
    textarea::placeholder {
      padding: 20px 10px 10px;
    }

    .closeReply {
      position: absolute;
      top: -10px;
      left: 90%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--color);
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
  }

  .chatActionButtons {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0px;
    font-size: 14px;
    font-family: "Montserrat-SemiBold";
    .saveAndCloseBtn {
      min-width: 180px;
      height: 40px;
      border: 2px solid #000;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      transition: 0.1s linear;

      svg {
        margin: 0px 10px;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .replyToDinoBtn {
      min-width: 180px;
      height: 40px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border: 1px solid #ff6333;
      background-color: #ff6333;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      border-radius: 12px;

      svg {
        width: 24px;
        height: 24px;
        margin: 0px 10px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    margin-bottom: 50px;
    padding-bottom: 100px;

    .chatTimeline {
      width: 90%;
      margin-bottom: 30px;
    }

    .replyBox {
      width: 90%;
      margin-top: 8px;
      textarea {
        width: 95%;
      }
    }

    .chatActionButtons {
      justify-content: center;
      .saveAndCloseBtn {
        min-width: 155px;
        text-align: center;
      }

      .replyToDinoBtn {
        min-width: 170px;
      }
    }
  }
}
