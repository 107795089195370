.wrapper {
  .content {
    min-height: 84dvh;
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    overflow-x: hidden;
    .card {
      width: 80%;
      min-height: 140px;
      background-color: #fff;
      border: 2px solid var(--color);
      border-radius: 8px;
      margin: 0px auto 40px auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;

        span {
          &:first-child {
            font-size: 14px;
            font-family: "Montserrat-SemiBold";
            margin-bottom: 5px;
          }
          &:last-child {
            font-size: 16px;
          }
        }
      }
    }
    .locationImageCard {
      width: 95%;
      height: 195px;
      background-color: var(--color);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      margin: 0 auto;

      svg {
        margin-top: 10px;
      }
    }
  }
}
