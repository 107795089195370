.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  margin: 0 auto;
  box-shadow: 0px 0px 12.57034px 0px rgba(0, 0, 0, 0.12);
  max-height: 630px;
  max-width: 1200px;

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 66px;
    align-items: center;

    svg {
      cursor: pointer;
      width: 66px;
    }
  }

  .title {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0px auto;

    .helpTitle {
      width: 50%;
      span {
        font-size: 26px;
        line-height: 40px;
        letter-spacing: -0.28px;
        font-family: "Montserrat-Regular";
      }
    }
  }
  .content {
    width: 80%;
    // height: 33dvh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 30px auto 50px auto;

    .leftPart {
      width: 50%;
      margin-right: 70px;
      span {
        line-height: 40px;
        letter-spacing: -0.28px;
        font-family: "Montserrat-Regular";
      }

      .textBox {
        position: relative;
        width: 90%;

        textarea {
          width: 100%;
          border-radius: 8px;
          resize: none;
          outline: none;
          padding: 10px;
          font-family: "Montserrat-Regular";
          font-size: 16px;
        }
        textarea::placeholder {
          padding: 20px 10px 10px;
        }

        .clearChatIcon {
          position: absolute;
          right: -22px;
          bottom: 4px;
          background-color: #000;
          width: 30px;
          border-top-left-radius: 8px;
          border-bottom-right-radius: 8px;
          height: 30px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-size: 14px;
          }
        }
      }

      .button {
        span {
          font-size: 16px;
          font-family: "Montserrat-SemiBold";
        }
      }
    }

    .rightPart {
      width: 50%;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 12.57034px 0px rgba(0, 0, 0, 0.12);
      height: 200px;
      border-radius: 8px;
      padding: 10px;

      .tabs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .tab {
          width: 60px;
          height: 36px;
          border: 1px solid #c1c6d5;
          border-radius: 4px;
          cursor: pointer;
          margin: 0px 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: hover 0.2s ease-in-out;

          &:hover {
            background-color: #c1c6d5;
          }
        }
      }
      .tabContent {
        div {
          margin: 15px 0px;
          cursor: pointer;
        }

        h4 {
          margin: 5px 0px 0px 0px;
          font-family: "Montserrat-SemiBold";
          font-size: 16px;
        }
      }
    }
  }

  .generatingAnswerButton {
    min-height: 55px;
    margin-top: 30px !important;
  }
  .generatingAnswerButton,
  .button {
    width: 195px;
    height: 55px;
    padding: 0px 10px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ff6333;
    background-color: #ff6333;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-radius: 12px;
    margin: 10px auto 50px auto;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    font-family: "Montserrat-SemiBold";

    div {
      margin: unset !important;
      width: 50px !important;
      height: 55px !important;
      display: flex;
      align-items: center;

      svg {
        width: 40px !important;
        height: 40px !important;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    svg {
      width: 50px;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1250px) {
    .content {
      width: 90%;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    border: none;
    background-color: transparent;
    box-shadow: unset;

    .title {
      margin: 0 auto;
      div {
        &:first-child {
          width: 100%;
        }

        &:last-child {
          display: none;
        }
      }
    }

    .content {
      width: 100%;
      flex-direction: column;
      margin: 0 auto;
      justify-content: unset;
      height: unset;
      align-items: center;

      .leftPart {
        margin-right: 20px;
        span {
          font-size: 24px;
        }

        textarea {
          margin-top: 10px !important;
        }

        .textBox {
          width: 100%;
        }
      }

      .leftPart,
      .rightPart {
        width: 80%;
      }

      .rightPart {
        margin-top: 10px;
        background-color: #fff;
        span {
          font-size: 15px;
        }

        .tabs {
          .tab {
            text-align: center;
          }
        }
      }
    }
    .button {
      margin-top: 30px;
      svg {
        width: 50px;
      }
    }
    .generatingAnswerButton {
      margin: 30px auto 50px auto;
    }
  }
}
