.container {
  width: 90%;
  max-width: 1000px;
  min-height: 60dvh;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 12px;
  text-align: center;

  h1 {
    font-size: 28px;
    text-transform: uppercase;
    padding: 30px 10px 0px 10px;
    margin-bottom: 0px;
  }

  .gameOfTheDayContainer {
    width: 95%;
    min-height: 560px;
    margin: 20px auto;
    background: radial-gradient(
        109.99% 110.24% at 117.11% 123.01%,
        var(--color),
        rgba(84, 10, 157, 0) 100%
      ),
      radial-gradient(141.11% 141.42% at 0% 0%, var(--color) 0%, #050008 100%);
    border-radius: 8px;

    .header {
      height: 30px;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      padding-right: 20px;

      svg {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }

      .medalsToWin {
        width: 36px;
        height: 27px;
        border-radius: 14.5px;
        color: #000;
        background: rgb(248, 190, 1);
        background: -moz-linear-gradient(
          129deg,
          rgba(248, 190, 1, 1) 0%,
          rgba(234, 131, 1, 1) 100%
        );
        background: -webkit-linear-gradient(
          129deg,
          rgba(248, 190, 1, 1) 0%,
          rgba(234, 131, 1, 1) 100%
        );
        background: linear-gradient(
          129deg,
          rgba(248, 190, 1, 1) 0%,
          rgba(234, 131, 1, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8be01",endColorstr="#ea8301",GradientType=1);
        font-size: 12px;
        font-family: "Montserrat-SemiBold";
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // .content {
    //   width: 80%;
    //   color: #fff;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   margin: 0 auto;

    //   .animation,
    //   .gameInfo {
    //     width: 50%;
    //   }

    //   .animation {
    //     font-size: 80px;
    //     font-family: "Montserrat-Bold";
    //   }

    //   .gameInfo {
    //     display: flex;
    //     align-items: center;
    //     flex-direction: column;
    //     height: 300px;
    //     justify-content: space-around;
    //   }
    // }
  }

  .gamesInfoButton {
    width: 95%;
    height: 90px;
    border: 1px solid var(--color);
    background-color: #f2e6ff;
    border-radius: 12px;
    margin: 0 auto 100px auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      span {
        color: #1a7fdc;
        font-size: 12px;
      }

      svg {
        margin-left: 5px;
      }
    }

    h1 {
      font-family: "Dosis-Bold";
      font-size: 25px;
      margin-top: 0px;
      padding-top: 10px;
    }
  }

  @media screen and (max-width: 1180px) {
    min-height: 90dvh;
    padding-bottom: 10px;

    h1 {
      font-size: 20px;
    }

    // .content {
    //   flex-direction: column;

    //   .gameInfo {
    //     height: 200px !important;
    //     padding-bottom: 50px;

    //     div {
    //       h1 {
    //         margin-top: 0px;
    //         padding-top: 0px;
    //       }
    //     }
    //   }

    //   .animation,
    //   .gameInfo {
    //     width: 100% !important;
    //   }
    // }
  }
}
