.container {
  color: #333;
  font-family: "Arial", sans-serif;
  overflow: auto;

  .landing {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    background-image: linear-gradient(
      to bottom,
      #ffa19a,
      #ffa79f,
      #ffada5,
      #ffb3aa,
      #ffb9b0,
      #feb7ae,
      #fcb5ac,
      #fbb3aa,
      #f8a9a0,
      #f59e96,
      #f2948d,
      #ee8984
    );

    .header {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 40px;

      .logo {
        width: 115px;
        height: 45px;
      }

      .headerBtn {
        width: 25px;
        height: 25px;
        background-color: transparent !important;
        border: 1px solid #fff;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 2px;
        cursor: pointer;
      }
    }

    .content {
      width: 60%;
      height: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftSection {
        span {
          color: #fff;
          font-family: "Montserrat-Light";
          font-style: normal;
          letter-spacing: 10px;
          font-size: 56px;
        }
      }
      .rightSection {
        position: relative;
        z-index: 10;

        .roseGlowCan {
          z-index: 10;
        }

        .bottleShadow {
          position: absolute;
          bottom: -26px;
          left: -315px;
          width: 800px;
          height: 100px;
          z-index: -1;
        }

        .autumnSaleBadge {
          position: absolute;
          bottom: -30px;
          left: -70px;
          width: 100%;
        }
      }
      .leftSection,
      .rightSection {
        button {
          background-color: #fff !important;
          color: #ef9890 !important;
          width: 190px;
          margin-top: 50px;
          font-family: "Montserrat-SemiBold";
          text-transform: uppercase;
        }
      }
    }
    .movingText {
      width: 100%;
      overflow-x: hidden;
      position: relative;

      article {
        margin: 0px auto;
        overflow: hidden;
        position: relative;
        min-height: 4em;
        display: flex;
        align-items: center;

        .leftMoving {
          display: inline-block;
          white-space: nowrap;
          position: relative;
          will-change: transform;
          animation: scroll 60s linear infinite;
          font-size: 35px;
          font-family: "Hisdella-Regular";
          letter-spacing: 5px;
          color: #fff;
          padding-bottom: 20px;

          span {
            width: 100%;
          }
        }
        .leftMoving::after {
          content: attr(data-text);
          padding-left: 100px;
        }

        @-webkit-keyframes scroll {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-100%);
          }
        }

        @keyframes scroll {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-100%);
          }
        }
      }
    }
  }
  .shopContent {
    width: 90%;
    min-height: 50dvh;
    margin: 100px auto 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-shrink: 1;

    .top {
      span {
        color: #eb7d80;
        font-size: 16px;
      }

      h1 {
        font-family: "Montserrat-Bold";
        font-size: 48px;
        text-transform: uppercase;
      }
    }

    .products {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .item {
        width: 32%;
        height: 410px;
        border-radius: 24px;
        border: 1px solid #c1c8cc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        img {
          max-width: 200px;
        }

        .price {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          span {
            &:first-child {
              text-decoration: line-through;
              font-size: 12px;
              font-family: "Montserrat-SemiBold";
              color: #eb7d80;
              margin-bottom: 10px;
            }

            &:last-child {
              font-family: "Montserrat-SemiBold";
              font-size: 16px;
            }
          }
        }

        button {
          border: 1px solid #eb7d80;
          border-radius: 16px;
          color: #eb7d80 !important;
          background-color: transparent !important;
          font-family: "Montserrat-SemiBold";
          margin-top: 20px;
        }
      }
    }

    .labels {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 50px 0px;

      span {
        font-size: 13px;
        &:first-child {
          padding-bottom: 10px;
        }
      }

      button {
        background-color: #000 !important;
        font-family: "Montserrat-Medium";
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 50px;
      }
    }

    .aboutRoseGlow {
      width: 100%;
      font-size: 170px;
      line-height: 120px;
      font-family: "Hisdella-Regular";
      color: #f9978e;
      transform: rotate(-10deg);
      margin-top: 200px;
      margin-left: -10%;

      span {
        &:last-child {
          margin-left: 35%;
        }
      }
    }

    .canInfo {
      width: 80%;
    }
  }
  .canInfo {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 70%;
    justify-content: center;
    margin: 100px auto 40px auto;
    line-height: 32px;
    font-size: 20px;
  }
  .roseGlassContent {
    height: 100dvh;
    background-image: url("../../../assets/images/rose-glass-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 100px;
    overflow: hidden;

    div {
      width: 100%;
      font-size: 240px;
      line-height: 180px;
      font-family: "Hisdella-Regular";
      color: #fff;
      transform: rotate(-8deg);
      position: absolute;
      bottom: 90px;
      left: 20%;
      span {
        &:last-child {
          margin-left: 12%;
        }
      }
    }
  }

  .furtherDetailsContainer {
    width: 70%;
    margin: 100px auto 20px auto;

    h1 {
      text-transform: uppercase;
      font-family: "Montserrat-SemiBold";
      font-size: 36px;
    }

    span {
      font-size: 20px;
      line-height: 32px;
    }

    .wineDetails {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 100px;

      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        div {
          display: flex;
          flex-direction: column;
          min-width: 240px;
          align-items: flex-start;

          span {
            &:first-child {
              color: #ff6333;
              font-size: 16px;
            }
            &:last-child {
              font-size: 24px;
              font-family: "Montserrat-SemiBold";
            }
          }
        }
      }
    }
  }

  .quoteBox {
    height: 90px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding-left: 20px;
    margin-top: 30px;
    background-image: linear-gradient(
      to right bottom,
      #ffdfdf,
      #fee7ed,
      #fceff7,
      #fbf8fd,
      #ffffff
    );

    h1 {
      font-size: 24px;
      margin-left: 20px;
    }
  }
  .lightRoseLabel {
    margin-top: 40px;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 80px;
  }

  @media screen and (max-width: 1800px) {
    .roseGlassContent {
      height: 50dvh;
      margin-top: 0px;

      div {
        bottom: 40px;
        left: 15%;
      }
    }
  }

  @media screen and (max-width: 1700px) {
    .roseGlassContent {
      div {
        bottom: 40px;
        left: 11%;
      }
    }
  }

  @media screen and (max-width: 1550px) {
    .shopContent {
      .aboutRoseGlow {
        font-size: 150px;
        margin-left: -7%;
      }
    }
    .roseGlassContent {
      div {
        font-size: 180px;
        line-height: 180px;
        bottom: 40px;
        left: 15%;
      }
    }
  }

  @media screen and (max-width: 1370px) {
    .roseGlassContent {
      div {
        font-size: 160px;
        line-height: 140px;
      }
    }
  }
  @media screen and (max-width: 1180px) {
    .landing {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around !important;
      overflow-x: hidden;

      .header {
        padding-top: 20px;
        margin-bottom: 30px;
      }

      .content {
        width: 100%;
        height: 80%;
        flex-direction: column;
        justify-content: space-around;

        .leftSection {
          span {
            font-size: 34px;
          }
        }

        .rightSection {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            font-size: 14px;
          }

          .roseGlowCan {
            height: 40dvh;
          }

          .bottleShadow {
            bottom: 69px !important;
            left: -165px !important;
            width: 480px !important;
          }

          .autumnSaleBadge {
            position: absolute;
            bottom: 60px;
            left: -30px;
            z-index: 99;
            width: 130px;
          }
        }
      }
    }

    .furtherDetailsContainer {
      width: 80%;

      h1 {
        font-size: 24px;
      }
    }

    .canInfo {
      text-align: left;
      width: 80%;
    }
    .shopContent {
      .top {
        h1 {
          font-size: 34px;
        }
      }
      .products {
        flex-direction: column;

        .item {
          width: 98%;
          margin-bottom: 10px;
        }
      }

      .aboutRoseGlow {
        font-size: 120px;
        margin-left: -5%;
        margin-top: 100px;
      }
    }

    .wineDetailsMobile {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;

        &:first-child {
          text-align: left;
          color: #ff6333;
          font-size: 16px;
        }
        &:last-child {
          text-align: right;
          font-size: 16px;
          font-family: "Montserrat-SemiBold";
        }

        span {
          padding-bottom: 20px;
        }
      }
    }

    .quoteBox {
      h1 {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .roseGlassContent {
      div {
        font-size: 140px;
        left: 10%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .shopContent {
      .aboutRoseGlow {
        font-size: 90px;
        line-height: 60px;
      }
    }
  }
  @media screen and (max-width: 820px) {
    .roseGlassContent {
      div {
        font-size: 120px;
        left: 11%;
        bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .shopContent {
      .aboutRoseGlow {
        font-size: 50px;
        line-height: 60px;
        margin-left: -13%;
      }
    }
    .roseGlassContent {
      div {
        font-size: 100px;
        line-height: 100px;
        left: 7%;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .roseGlassContent {
      div {
        font-size: 70px;
        line-height: 60px;
        left: 10%;
        bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .roseGlassContent {
      div {
        left: 6%;
      }
    }
  }
}
