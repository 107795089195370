.wrapper {
  .header {
    display: flex;
    width: 95%;
    margin: 10px auto;
    align-items: center;

    .tabs {
      width: calc(100% - 40px);
      display: flex;
      justify-content: center;
      margin-left: 5px;

      div {
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-right: 0px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: 0px;
        }
      }

      .activeTab {
        background-color: #000;
        color: #fff;
      }
      .tab,
      .activeTab {
        min-width: 100px;
        border: 1px solid #000;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
}
