.emptyBasketContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px 0px;
  background-color: #fff;
  min-height: 70dvh;

  .emptyBasketLabel {
    color: #000;
    text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat-Bold";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 20px 0px;
  }

  .prepareCheckoutContent {
    margin: 50px auto 0px auto;
    width: 70%;

    .label {
      color: var(--color);
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 22px;
      font-style: normal;
      line-height: 0px;
      text-transform: uppercase;
    }
  }

  .lastOrderContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 70px auto 10px auto;
    text-align: left;

    .title {
      font-size: 16px;
    }
    .lastOrderTitle {
      text-transform: uppercase;
      color: var(--color);
      font-size: 16px;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    .loadOrderBtn {
      min-width: 70px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
      background-color: #97979760;
      cursor: pointer;
      font-size: 16px;
      font-family: "Montserrat-Bold";
      padding: 0px 10px;
      margin-top: 20px;
    }

    @media screen and (max-width: 1180px) {
      width: 80%;

      .content {
        flex-direction: column;
      }
    }
  }
}

.neckTieContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &:last-child {
        min-width: 80px;
        height: 32px;
        background-color: #dbf6ff;
        border: 1px solid #1a7fdc;
        color: #1a7fdc;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        font-family: "Montserrat-Medium";
      }
    }

    span {
      font-size: 12px;
    }

    h2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
      margin-top: 10px;
    }
  }

  .scrollableContainer {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px;
    gap: 1rem;
    scrollbar-width: thin;
    height: 280px;
    align-items: flex-end;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 3px;
    }

    .selected {
      // border: 2px solid var(--color) !important;
      border-left: 2px solid var(--color) !important;
      border-right: 2px solid var(--color) !important;
      border-bottom: 2px solid var(--color) !important;
    }

    .necktieItem {
      flex: 0 0 auto;
      width: 150px;
      height: 230px;
      border: 2px solid transparent;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      transition: border-color 0.2s;
      box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
      background-color: #fff;
      position: relative;

      .necktieTop {
        position: absolute;
        top: -36px;
        left: -2px;
        width: 154px;
      }
      .deselect {
        position: absolute;
        top: -44px;
        right: 0px;
        background-color: var(--color);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        z-index: 9;
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .content {
        width: 80%;
        padding: 10px;
        border-radius: 5px;
        font-size: 18px;
        font-family: "Montserrat-Bold";
        text-align: left;

        img {
          width: 70px;
          height: 120px;
          object-fit: contain;
        }
      }
      .bottomContent {
        min-height: 40px;
        width: 100%;
        font-size: 12px;
        font-family: "Montserrat-Medium";

        .unselectedItem {
          color: #979797 !important;
        }

        .selectedItem {
          color: #000 !important;
        }

        .necktieQuantity {
          height: 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          border: 1px solid #979797;
          border-radius: 12px;
          width: 79%;
          margin: 5px auto;

          div {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.bottomLabel {
  display: flex;
  justify-content: flex-end;
  color: #979797;
  margin-top: 20px;
  font-size: 16px;
  font-family: "Montserrat-Medium";
  cursor: pointer;
}

.previewContainer {
  border-radius: 5px;

  .previewedWine {
    height: 400px;
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
  }
}

.previewPersonalNote {
  min-height: 300px;
  position: relative;

  .topLeft {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .topRight {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .bottomLeft {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
  .bottomRight {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 290px;

    .link {
      font-size: 10px;
    }
    img {
      width: 50px;
    }

    h2 {
      font-family: "GreatVibes-Regular";
      font-size: 30px;
      width: 100%;
      text-align: center;
      word-wrap: break-word;
    }
  }
}

.nonEmptyBasketNecktie {
  margin-bottom: 50px;
  .neckTieContainer {
    width: 100% !important;
  }
}

.personalNoteContent {
  margin: 30px 0px;
  max-width: 300px;
  position: relative;

  .infoIcon {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
  span {
    font-size: 12px;
  }
  h2 {
    font-size: 18px;
    font-family: "Montserrat-Bold";
    margin-top: 10px;
  }

  textarea {
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    min-height: 120px;
    border: none;
    border-radius: 12px;
    outline: none;
    padding: 20px;
    resize: none;
    box-sizing: border-box;
    font-family: "Montserrat-Regular";
    font-size: 16px;
  }
}
.actionBox {
  min-height: 75px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 30px;

  .title {
    color: var(--color);
    font-size: 20px;
  }
  div div label {
    @media screen and (max-width: 1180px) {
      margin-right: 0px !important;
    }
  }

  .cardFields {
    width: 60%;
  }

  .deliveryMethodContent {
    display: flex;
    align-items: center;
    width: 100%;

    .leftPart {
      width: calc(100% - 40px);
    }
    .rightPart {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        &:first-child {
          color: var(--color);
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-Bold";
          font-size: 16px;
          font-style: normal;
          line-height: 22.111px;
          margin-bottom: 10px;
        }
      }
    }
    .toggleItem {
      display: flex;

      div {
        max-width: unset;
        margin: 5px 0px;
        label {
          color: #000;
          font-family: "Montserrat-Bold";
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
        }
      }
    }

    .rgZurichExpressShipment {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 4px;
      border: 1px solid #ffa19a;
      padding: 0px 20px;

      div {
        max-width: unset;
        margin: 5px 0px;
        min-width: 20px;

        label {
          color: #000;
          font-family: "Montserrat-Bold";
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
        }
      }

      .timeslotContainer {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // margin-left: 50px;
        max-width: 800px;

        div {
          div {
            margin-right: 5%;
          }
        }

        .selectContent {
          display: flex;
          align-items: center;

          .select {
            margin-left: 40px;
            width: 110px;

            select {
              width: 130px;
              border: 1px solid #bec8cf;
              border-radius: 4px;
              height: 32px;
              outline: none;
              font-family: "Montserrat-SemiBold";
              font-size: 12px;
              padding: 5px 10px;
              color: #000 !important;
            }
          }
        }
      }
    }

    .next1hShipmentPopup {
      min-width: 70px;
      width: 70px;
      height: 25px;
      color: #1a7fdc;
      border: 1px solid #1a7fdc;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      cursor: pointer;
      margin-left: 40px !important;

      svg {
        margin-right: 10px;
      }
    }
  }
  .gerstlDelivery {
    font-size: 11px;
    font-style: normal;
    line-height: 20px;
    margin-top: 10px;
  }
  .deliveryMethodHint {
    text-align: center;
    margin-top: 20px;
  }

  .addressesWrapper {
    display: flex;
    justify-content: space-between;

    .dAddressContent,
    .bAddressContent {
      width: 50%;
      display: flex;
      flex-direction: column;

      input,
      select,
      label {
        width: 60%;
        min-height: 20px;
      }

      .titleLabel {
        margin-bottom: 10px;
        color: var(--color);
      }

      span {
        margin-bottom: 10px;
      }
    }
  }

  .labelWithIcon {
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: 10px;
    }

    span {
      color: #000;
      font-family: "Montserrat-Bold";
      font-size: 18px;
      font-style: normal;
      line-height: normal;
    }
  }
}

.membershipDiscountContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .text {
    font-size: 14px;
    font-family: "Montserrat-Regular";
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0px !important;
    }
  }
}

.guestUserDataHeader {
  display: flex;
  justify-content: space-between;

  .editIcon {
    cursor: pointer;
  }
}

.checkoutInformations {
  margin-bottom: 40px;
  font-size: 16px;
}

.guestUserContent {
  max-width: 80%;
  div {
    margin-bottom: 10px;

    span {
      word-wrap: break-word;
    }
  }
}

.shipmentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    margin-top: 0px !important;
  }

  .editIcon {
    cursor: pointer;
  }

  .setAddressesButtons {
    display: flex;
    margin-right: 20px;
    justify-content: space-between;
    width: 280px;
    text-align: center;

    div {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:first-child {
        background-color: #fff;
        border: 1px solid var(--color);
        margin-right: 10px;
        text-transform: uppercase;
      }
      &:last-child {
        background-color: var(--color);
        color: #fff;
      }
    }
  }
}

.deliveryDetails {
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
}

.cartSummaryDetails {
  padding: 20px;
  width: 95%;
  background-color: #fff;
  min-height: 90%;
  margin-bottom: 100px !important;

  h1 {
    text-align: center;
    font-size: 20px;
  }

  .title {
    padding-bottom: 20px;
    color: #000;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "Montserrat-Bold";
    font-size: 18px;
    font-style: normal;
    line-height: 0px;
  }

  .coloredLabel {
    color: var(--color);
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 22px;
    text-transform: uppercase;
    margin-top: 50px;
  }

  .basketRow {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    margin-bottom: 50px;
  }

  .underBasketItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    .continueShoppingLabel {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    .cartItemsLength {
      color: #979797;
      font-size: 16px;
      font-family: "Montserrat-Medium";
    }
  }

  .promoCodeInput {
    margin-top: 30px;
  }
}

.totalAmountTitle {
  color: #000;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Montserrat-Bold";
  font-size: 18px;
  font-style: normal;
  line-height: 0px;
  padding-bottom: 10px;
}

.totalAmountBox {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  text-align: right;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px 20px;
  color: #fff;
  background-color: #fff;

  span {
    min-width: 310px;
    color: #000;
    text-align: right;
    font-family: "Montserrat-Bold";
    font-style: normal;
    line-height: 20px;
    text-align: right;
    font-size: 16px;
    line-height: 18px;
  }

  .loader {
    position: absolute;
    right: 20px;
    div {
      background-color: #fff !important;
      padding: unset !important;
    }
  }
}

.paymentMethods {
  width: 98%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .method {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 52px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    padding: 0px 20px;
    border-radius: 8px;
    background-color: #fff;

    label {
      background-color: transparent;
    }

    div {
      display: flex;
      justify-content: center;
    }
    div div div {
      input {
        margin-right: 20px;
      }
    }

    .checkbox {
      display: flex;
      justify-content: flex-start;
    }
    .icon {
      margin-left: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.mainButtons {
  display: flex;
  margin: 20px 0px;
  width: 100%;

  button {
    text-transform: uppercase;
  }
}

.totalLabel {
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  background-color: #000;
  margin: 10px 0px;
  color: #fff;
  border-radius: 4px;

  span {
    &:last-child {
      font-family: "Montserrat-SemiBold";
    }
  }
}

.payWithDefaultCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin: 40px 40px 40px 0px;

  div {
    margin: unset;

    input {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1800px) {
  .timeslotContainer {
    width: 65% !important;
  }
}
@media screen and (max-width: 1450px) {
  .timeslotContainer {
    width: 80% !important;
  }
}

@media screen and (max-width: 14250px) {
  .timeslotContainer {
    width: 90% !important;
  }
}

@media screen and (max-width: 1180px) {
  .selectContent {
    flex-direction: column;
  }
  .cartItemsContainer {
    width: 92%;
    .products {
      flex-direction: column;

      .item {
        width: 100%;
      }
    }
  }

  .cardFields {
    width: 100% !important;
  }

  .personalNoteContent {
    max-width: unset;
    margin: unset;
  }

  .shipmentHeader {
    align-items: flex-start;
  }

  .cartSummaryDetails {
    width: 90%;
    background-color: transparent;
    padding: 10px;
    margin: 0 auto;

    .actionBox {
      margin: 0px auto 60px auto;

      .addressesWrapper {
        flex-direction: column;

        .dAddressContent,
        .bAddressContent {
          width: 100%;

          input,
          select,
          label {
            width: 100%;
            min-height: 20px;
          }
        }
      }

      .deliveryMethodContent {
        flex-direction: column;
        margin-bottom: 20px;

        .leftPart {
          width: 100%;
        }

        .rightPart {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 40px;
        }

        .rgZurichExpressShipment {
          width: unset !important;
          margin: 10px 0px;
          padding-top: 10px;

          div {
            margin: 2px 0px;
          }

          .timeslotContainer {
            flex-direction: column;
            align-items: flex-start;
            margin: 20px 0px 20px 40px;
            max-width: 180px;

            div {
              div {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }

    .paymentMethods {
      float: unset;
      width: unset;
      margin-top: 0px;
      margin-bottom: 20px;
      max-width: unset;

      .method {
        width: unset !important;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .title {
      width: 80%;
      span {
        line-height: normal;
      }
    }
    .basketRow {
      width: 100%;
      margin: 0 auto;
      flex-direction: row;
      overflow-x: scroll;

      &div:first-child {
        width: 100%;
      }
    }
  }

  .headerPart {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .titleLabel {
      color: var(--color);
      font-size: 18px;
    }
  }

  .checkoutInformations {
    div {
      margin-bottom: 5px;
    }
  }

  .totalAmountTitle {
    padding-bottom: 30px;
  }

  .totalAmountBox {
    margin: 15px auto 20px auto;
    text-align: center;
  }

  .mainButtons {
    width: 100%;
  }

  .neckTieContainer {
    width: 80% !important;
    margin-bottom: 20px;

    .scrollableContainer {
      .necktieItem {
        width: 145px !important;

        .necktieTop {
          top: -34px;
          left: -2px;
          width: 149px;
        }
      }
    }

    .top {
      h2 {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cartSummaryDetails {
    h1 {
      text-align: unset;
    }
    .title {
      margin-left: 0px;
      word-wrap: break-word;
    }
  }
}

.expressShipment2hPopup {
  display: flex;
  flex-direction: column;
  text-align: justify;
  color: #1a7fdc;
  font-size: 12px;
}

.personalNoteInfoContent {
  display: flex;
  flex-direction: column;

  span {
    color: #1a7fdc;
    font-family: "Montserrat-Medium";
    &:last-child {
      margin-top: 20px;
    }
  }
}
