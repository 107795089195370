.wineOrdersWrapper {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .row,
  .firstCol,
  .secondCol,
  .thirdCol {
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    border: 1px solid #dde3eb;
    border-radius: 20px;
  }

  .row {
    width: 92%;
    display: flex;
    margin: 10px 0px;
    padding: 20px;
  }

  .firstCol,
  .secondCol,
  .thirdCol {
    width: 25%;
    height: 195px;
    margin: 0px 10px;
    padding: 0px 20px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    .expandIcon {
      position: absolute;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }
  }

  .firstCol {
    display: flex;
    align-items: center;

    .title {
      margin: 0px 0px 10px 0px;
    }

    .bottleSizeLabel {
      font-size: 16px;
    }

    .imagePart {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
        min-height: 100px;
        height: 100%;
        object-fit: cover;
      }
    }

    .rightPart {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      margin-left: 10px;
    }
  }

  .secondCol {
    display: flex;
    justify-content: center;
    align-items: center;

    .noRatingsBox {
      width: 70%;
      height: 48px;
      border: 2px solid var(--color);
      border-radius: 4px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Montserrat-Medium";
      font-size: 15px;
      color: var(--color);
      cursor: pointer;
      transition: 0.2s ease-in-out;
      text-align: center;
      padding: 0px 5px;

      &:hover {
        background-color: var(--color);
        color: #fff;
      }
    }

    .productFeedback {
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .headerLabel {
        font-family: "Montserrat-SemiBold";
        font-size: 14px;

        .tastingLabel {
          font-family: "Montserrat-Regular";
        }
      }

      svg {
        width: 27px;
      }

      .rateEmojis {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .activeIcon {
          border: 2px solid #ff6333;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          margin-top: 6px;
          margin-right: 5px;
        }

        .disabled {
          opacity: 0.5;
          margin-right: 5px;
          height: 30px;
        }
      }
    }
  }

  .thirdCol {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  .lastColumn {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 32px 0px 10px 0px;
    position: relative;

    .actionsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-left: 5px;
      margin-top: 20px;
    }

    .requestedToSupplyLabel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin-left: 20px;
      color: var(--color);
      font-size: 12px;
      margin-right: 5px;
    }

    .deleteWineIcon {
      border: 1px solid #000;
      border-radius: 4px;
      padding: 10px 15px;
      width: 30px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    .vfmIlustration {
      position: absolute;
      right: 0px;
      cursor: pointer;
      svg {
        width: 50px;
        height: 20px;
        overflow: visible;
      }
    }

    .addPriceValueBtn {
      position: absolute;
      right: 0px;
      width: 79px;
      height: 21px;
      font-size: 12px;
      font-family: "Montserrat-Medium";
      border: 1px solid var(--color);
      text-align: center;
      border-radius: 8px;
      background-color: #f2e6ff;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--color);
    }

    .mainBtn {
      width: 200px;
      height: 40px;
      background-color: var(--color);
      color: #fff;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        opacity: 0.9;
      }

      @media screen and (max-width: 1300px) {
        width: 150px;
      }
    }
  }

  .title,
  .price {
    margin-left: 10px;
  }

  .title {
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    margin-bottom: 5px;
  }

  .noOrdersText {
    font-family: "Montserrat-SemiBold";
    font-size: 20px;
    margin-top: 50px;
  }

  .discount {
    color: #ff6333;
    font-size: 12px;
    text-decoration: line-through;
    font-family: "Montserrat-SemiBold";
  }

  .price {
    font-family: "Montserrat-Regular";
    font-size: 24px;
  }

  .expandedItem {
    width: 94%;
    display: flex;

    .row {
      width: 25%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .firstCol,
      .secondCol,
      .thirdCol,
      .lastColumn {
        width: 92%;
        margin-bottom: 20px;
        border: 1px solid #dde3eb;
      }

      .lastColumn {
        border: none !important;
        .reorderBtn {
          margin-top: 30px;
        }
      }
    }
    .biggerContainer {
      width: 75%;
      margin: 10px 0px 10px 20px;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    }
  }

  .emptyStream {
    width: 90%;
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    margin: 40px auto;
    text-align: center;
  }
}
