.wrapper {
  .header {
    display: flex;
    width: 95%;
    margin: 10px auto;
    align-items: center;

    .tabs {
      width: calc(100% - 40px);
      display: flex;
      justify-content: center;
      margin-left: 5px;
      div {
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-right: 0px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: 0px;
        }
      }

      .activeTab {
        background-color: #000;
        color: #fff;
      }
      .tab,
      .activeTab {
        min-width: 100px;
        border: 1px solid #000;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  .content {
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    overflow-x: hidden;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);

    .card {
      width: 80%;
      min-height: 140px;
      background-color: #fff;
      border: 2px solid var(--color);
      border-radius: 8px;
      margin: 0px auto 40px auto;
      padding: 20px;
      display: flex;
      justify-content: space-around;

      .rightPart {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;
      }

      .title {
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
      }
      .imagePart {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100px;
          height: 150px;
          object-fit: cover;
        }
      }
    }

    .bottleLabels {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .wineTypeLabel {
        font-size: 18px;
        font-family: "Montserrat-SemiBold";
        color: var(--color);
        margin-bottom: 10px;
      }

      span {
        padding: 5px 0px;
      }
    }

    .middleLabels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      span {
        padding: 5px 0px;
      }

      .firstLabel {
        font-size: 14px;
        text-transform: uppercase;
      }

      .bold {
        font-size: 24px;
        font-family: "Montserrat-SemiBold";
      }

      .year {
        color: #797979;
        font-size: 14px;
        text-transform: uppercase;
      }

      .price {
        font-size: 24px;
        font-family: "Montserrat-SemiBold";
        margin-top: 20px;
      }
    }

    .informations {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 auto;
      text-align: left;

      div {
        margin: 10px 0px;
      }
    }

    .editContainer {
      display: flex;
      justify-content: flex-end;
      .editButton {
        background-color: var(--backgroundColor);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
      }
    }
  }

  .proposeToSupplyCard {
    min-height: 195px;
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);

    .infoLabels {
      display: flex;
      flex-direction: column;
      span {
        margin-bottom: 10px;
        &:first-child {
          font-size: 14px;
          font-family: "Montserrat-SemiBold";
        }
        &:last-child {
          font-size: 24px;
        }
      }
    }

    .actionContent {
      display: flex;
      justify-content: center;
    }
  }
}
