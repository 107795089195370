.header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background-color: #ffffff4d;
  top: 0;
  position: sticky;
  z-index: 999;
  padding-top: 10px;

  .icon {
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .leftPart {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-family: "Montserrat-SemiBold";
    font-size: 18px;
    color: var(--color);

    svg {
      margin-right: 10px;
    }
  }

  .rightPart {
    text-align: right;

    div {
      margin-right: 20px;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-top: 2px;
      margin-right: 14px !important;
      position: relative;
      svg {
        width: 100%;
        height: 100%;
      }

      .badge {
        position: absolute;
        top: -2px;
        right: 2px;
      }
    }

    .searchIconLedger {
      margin-top: 10px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .learnMore {
      height: 32px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 0px 10px;
      border: 1px solid #1a7fdc;
      border-radius: 24px;
      color: #1a7fdc;
      font-size: 10px;
      font-family: "Montserrat-Medium";
      cursor: pointer;

      div {
        margin-right: 0px;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .closeButton {
    margin-left: 24px;
    cursor: pointer;
    svg {
      width: 33px;
    }
  }
}

.rightSideIconsPurchasing {
  display: flex;
  align-items: center;

  .icon {
    position: relative;

    &:first-child {
      margin-right: 10px;
    }

    .badge {
      position: absolute;
      top: 0px !important;
      right: 3px;
    }
  }
}
