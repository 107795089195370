.container {
  max-width: 100%;
  margin: 5px auto;

  .itemContainer {
    margin: 20px 0px;

    .header {
      width: 90%;
      margin: 10px auto 0px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;

      svg {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
    .item {
      width: 90%;
      height: 190px;
      margin: 10px auto 20px auto;
      border-radius: 20px;
      padding: 0px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #97979780;
      position: relative;

      .leftInfo {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .image,
        .info {
          display: flex;
          align-items: center;
        }

        .image {
          width: 40%;
          img {
            width: 40px;
            height: 145px;
            margin: 0 auto;
            object-fit: cover;
          }
        }
        .info {
          width: 59%;
          min-height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          word-break: break-word;
          margin-right: 4px;

          .small {
            font-size: 12px;
          }
          .colored {
            color: var(--color);
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-size: 14px;
            font-style: normal;
            line-height: 16px;

            .addPriceValueBtn {
              width: 79px;
              height: 21px;
              font-size: 12px;
              font-family: "Montserrat-Medium";
              border: 1px solid var(--color);
              text-align: center;
              border-radius: 8px;
              background-color: #f2e6ff;
              margin-bottom: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .vfmIlustration {
              svg {
                width: 50px;
                height: 20px;
                overflow: visible;
              }
            }
          }
          .discount {
            font-size: 12px;
            color: #ff6333;
            text-decoration: line-through;
          }
        }
      }

      .bordered {
        width: 1px;
        height: 90%;
        background-color: #97979780;
        position: absolute;
        left: 50%;
      }

      .rightInfo {
        width: 50%;

        .rateAndTasteShownContent {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .button {
            width: 145px;
            height: 48px;
            font-family: "Montserrat-Medium";
            font-size: 15px;
            color: var(--color);
            border: 1px solid var(--color);
            border-radius: 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .medalsInfo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px auto 0px auto;
            width: 70%;
            font-family: "Montserrat-SemiBold";
            font-size: 12px;
            color: #b93b14;

            svg {
              width: 34px;
              height: 34px;
              margin-right: 5px;
            }
          }
        }

        .productFeedback {
          width: 90%;
          height: 100%;
          min-height: 140px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-left: 15px;

          .emptyRateOrTaste {
            font-size: 10px;
            color: #ff6333;
          }

          .headerLabel {
            font-family: "Montserrat-SemiBold";
            font-size: 14px;

            .tastingLabel {
              font-family: "Montserrat-Regular";
            }
          }

          svg {
            width: 20px;
            height: 20px;
          }

          .rateEmojis {
            width: 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .activeIcon {
              border: 2px solid #ff6333;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 22px;
              width: 22px;
              margin: 6px 2px 0px 2px;
              padding: 2px;
            }

            .disabled {
              opacity: 0.5;
              margin: 10px 2px 0px 2px;
            }
          }
        }
      }

      .title {
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
  }
}

.emptyStream {
  width: 90%;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  margin: 40px auto;
  text-align: center;
}
