.wrapper {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  padding-bottom: 40px;

  .seperator {
    background-color: #313131;
    color: #ffff;
    width: 85%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 0px 20px;
    margin-top: 30px;

    &:first-child {
      margin-top: 20px !important ;
    }

    svg {
      width: 24px;
      height: 15px;
    }

    span {
      font-size: 12px;
    }
  }

  .emptyStream {
    width: 90%;
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    margin: 40px auto;
    text-align: center;
  }

  @media screen and (max-width: 1180px) {
    margin: 15px auto 30px auto;
    padding-bottom: 80px;

    .seperator {
      width: 80%;
    }
  }
}

.feedItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
