.detailsWrapper {
  padding: 20px;

  .header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;

    .wineTypeLabel {
      font-size: 18px;
      font-family: "Montserrat-SemiBold";
      color: var(--color);
    }

    .editButton {
      background-color: var(--backgroundColor);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .mainInfo {
    min-height: 350px;
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .leftPart {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      height: 80%;

      .bottleLabels {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        span {
          padding: 5px 0px;
        }
      }

      .middleLabels {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          padding: 5px 0px;
        }

        .firstLabel {
          font-size: 14px;
          text-transform: uppercase;
        }

        .bold {
          font-size: 24px;
          font-family: "Montserrat-SemiBold";
        }

        .year {
          color: #797979;
          font-size: 14px;
          text-transform: uppercase;
        }

        .price {
          font-size: 24px;
          font-family: "Montserrat-SemiBold";
          margin-top: 20px;
        }
      }

      .informations {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 auto;
        text-align: left;

        div {
          margin: 20px 0px;
        }
      }
    }

    .rightPart {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 220px;
        height: 400px;
        object-fit: contain;
      }

      .locationImageCard {
        width: 300px;
        height: 195px;
        background-color: var(--color);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        svg {
          margin-top: 10px;
        }
      }
    }
  }
}
