.wrapper {
  .header {
    display: flex;
    width: 95%;
    margin: 10px auto;
    align-items: center;

    .tabs {
      width: calc(100% - 40px);
      display: flex;
      justify-content: center;
      margin-left: 5px;

      div {
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-right: 0px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: 0px;
        }
      }

      .activeTab {
        background-color: #000;
        color: #fff;
      }
      .tab,
      .activeTab {
        min-width: 100px;
        border: 1px solid #000;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  .content {
    min-height: 84dvh;
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    overflow-x: hidden;

    .card {
      width: 80%;
      min-height: 140px;
      background-color: #fff;
      border: 2px solid var(--color);
      border-radius: 8px;
      margin: 0px auto 40px auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .active {
        background-color: #8e99a375;
      }
    }

    .infoIcon {
      display: flex;
      justify-content: center;
      margin: 0 auto 30px auto;
    }

    .headerLabel {
      height: 65px;
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      font-family: "Montserrat-SemiBold";
      font-size: 14px;

      .valueLabel {
        font-family: "Montserrat-Regular";
      }
      .emptyRateOrTaste {
        font-size: 10px;
        color: #ff6333;
      }
    }

    svg {
      width: 40px;
    }
  }
}

.infoPopup {
  h3 {
    text-align: center;
    font-size: 14px;
  }
  div {
    margin-bottom: 10px;
    color: #1a7fdc;
    font-size: 12px;
  }
}
