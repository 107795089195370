.cardContainer {
  width: 90%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0px;
  background-color: #fff;

  .chatTimeline {
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    .question {
      width: 100%;
      min-height: 95px;
      background-color: #f2e6ff80;
      border-radius: 12px;
      border: 1px solid var(--color);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 30px;
      text-align: center;

      span {
        padding: 0px 20px;
        &:first-child {
          font-size: 16px;
        }
      }
    }

    .answer {
      width: 80%;
      min-height: 95px;
      background-color: #f2e6ff80;
      border: 1px solid var(--color);
      border-radius: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 40px;
      padding: 20px;
      font-size: 15px;

      .answerTouchLine {
        position: absolute;
        left: -21px;
        top: -43px;
      }

      span {
        margin-bottom: 20px;
      }

      .colored {
        color: #ff6333;
        font-family: "Montserrat-Medium";
        margin-bottom: 5px;
      }

      .expandCard {
        width: 50px;
        height: 30px;
        border-radius: 8px;
        font-size: 24px;
        text-align: center;
        background-color: #000;
        color: #fff;
        position: absolute;
        bottom: 10px;
        left: calc(50% - 85px);
        cursor: pointer;
        z-index: 2;
      }
    }

    .answerOverlay {
      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) -10%,
        rgba(253, 187, 45, 0) 100%
      );
      background: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) -10%,
        rgba(253, 187, 45, 0) 100%
      );
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) -10%,
        rgba(253, 187, 45, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fdbb2d",GradientType=1);
      position: absolute;
      width: 100%;
      bottom: 0px;
      left: 0px;
      height: 250px;
      z-index: 1;
      border-radius: 12px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    width: 240px;

    .openChatAction {
      cursor: pointer;
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 1180px) {
    .chatTimeline {
      width: 90%;
      margin-bottom: 20px;

      .answer {
        .expandCard {
          left: calc(50% - 35px);
        }
      }
    }
  }
}
