.wrapper {
  width: calc(89% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: #fff;
  padding: 10px;
  border-radius: 18px;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .closeCard {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
    }

    h1 {
      font-family: "Montserrat-Bold";
      font-size: 32px;
    }

    span {
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px 0px;

    .card {
      width: 100%;
      min-height: 120px;
      border-radius: 12px;
      color: #fff;
      background: rgb(255, 67, 23);
      background: -moz-linear-gradient(
        162deg,
        rgba(255, 67, 23, 1) 0%,
        rgba(244, 137, 65, 1) 100%
      );
      background: -webkit-linear-gradient(
        162deg,
        rgba(255, 67, 23, 1) 0%,
        rgba(244, 137, 65, 1) 100%
      );
      background: linear-gradient(
        162deg,
        rgba(255, 67, 23, 1) 0%,
        rgba(244, 137, 65, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff4317",endColorstr="#f48941",GradientType=1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .col {
        // width: 33.33%;
        width: 100%;
        margin: 5px;
        display: flex;
        align-items: center;

        .bordered {
          width: 116px; // Single
          height: 75px;
          border: 1px solid #ffffff50;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin: 0px 20px;

          h3 {
            font-size: 15px;
            margin: 2px 0px 0px 0px;
          }

          // svg {
          //   margin-right: 10px;
          // }
        }

        .description {
          width: 70%;
          margin: 0 15px auto 0px;
          margin-top: 10px;
          font-size: 12px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .descriptionContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
    }

    .modules {
      width: 70%;
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;

      div {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .content {
      width: 100%;

      .card {
        .bordered {
          flex-direction: column;

          svg {
            margin: 0px !important;
          }

          h3 {
            margin: 5px 0px;
          }
        }
        // .description {
        //   width: 100% !important;
        // }
      }
    }
  }
}
