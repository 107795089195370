.wrapper {
  width: 100%;
  height: 380px;
  border-radius: 12px;
  background-color: #f2e6ff;
  margin: 0 auto;

  .header {
    padding: 15px;
    cursor: pointer;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  h4 {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    color: var(--color);
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
  }

  .content {
    width: 300px;
    height: 200px;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;

    .valueSelector {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px auto 40px auto;

      svg {
        width: 120px;
        height: 100% !important;
        overflow: visible;
      }
    }

    .selectedOption {
      background-color: var(--color) !important;
      color: #fff !important;
    }
    .firstOption,
    .secondOption,
    .thirdOption,
    .fourthOption,
    .fifthOption {
      position: absolute;
      width: 100px;
      height: 30px;
      color: var(--color);
      font-size: 10px;
      font-family: "Montserrat-SemiBold";
      background-color: #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        word-wrap: break-word;
        text-align: center;
      }
    }

    .firstOption {
      bottom: 50px;
      left: -20px;
    }

    .secondOption {
      bottom: 100px;
      left: 0px;
    }

    .thirdOption {
      bottom: 130px;
      left: 105px;
    }

    .fourthOption {
      bottom: 100px;
      right: -10px;
    }

    .fifthOption {
      bottom: 50px;
      right: -20px;
    }
  }

  button {
    width: 150px;
    margin: 0 auto;
    font-size: 14px;
    font-family: "Montserrat-SemiBold";
  }

  @media screen and (max-width: 1180px) {
    width: 90%;

    .content {
      .firstOption,
      .secondOption,
      .thirdOption,
      .fourthOption,
      .fifthOption {
        width: 90px;
      }

      .firstOption {
        left: -10px;
      }

      .secondOption {
        left: 10px;
      }

      .thirdOption {
        left: 110px;
      }

      .fourthOption {
        right: 0px;
      }

      .fifthOption {
        right: -10px;
      }
    }
  }
}
