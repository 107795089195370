.multiSelectDropdownContainer {
  position: relative;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  border: 1px solid #000;
  transition: height 0.3s ease-in-out;
  flex-direction: column;

  .dropdownLabel {
    width: 92%;
    padding: 5px;
    cursor: pointer;
    color: #000;
    text-align: center;
    font-family: "Montserrat-SemiBold";
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      position: absolute;
      right: 5px;
    }
  }

  .selectedDate {
    width: 96%;
    font-size: 16px;
    color: var(--color);
  }

  .dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    width: 190px !important;
    background-color: #fff;
    padding: 10px;
    min-height: 220px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    margin-top: 10px;
    max-height: 250px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .dateRanges {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0px;

      .dateLabel {
        font-size: 14px;
        color: #979797;
      }

      .datePicker {
        width: 130px;
      }
    }

    .resetFilterBtn {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-family: "Montserrat-Medium";
      color: #979797;
      margin-top: 10px;

      span {
        cursor: pointer;
      }
    }

    .button {
      width: 90%;
      background-color: #000;
      margin: 10px auto;
      border-radius: 5px;
      color: #fff;
      padding: 5px 5px;
      text-align: center;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    // height: 40px;
    background-color: #f8f9fb;
    margin: 10px 4px 10px 0px;
    border-radius: 7px;

    .dropdownLabel {
      color: #000;
      width: 95% !important;
    }

    .datePicker {
      width: 180px !important;
    }

    .dropdownContent {
      width: 96% !important;
    }
  }
}
