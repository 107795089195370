.addCustomFieldContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  .submitButton {
    margin: 50px 0px;
  }
  h2 {
    margin-top: 50px;
  }

  .inputs {
    width: 80%;
    margin-left: 30px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    input {
      background-color: #fff;
      height: 64px;
      border: none;
      border-radius: 4px;
      width: 100%;
      padding: 20px 15px 5px 15px;
      font-size: 16px;
      outline: none;
      margin: 5px 0px;
      font-family: "Montserrat-Medium";
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-shadow: 0px 1px 6px 0px rgba(220, 225, 234, 1);

      &::placeholder {
        color: #000;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    height: 50dvh;
    .inputs {
      margin-left: 0px;
    }
  }
}
