.wrapper {
  width: 400px;
  .item {
    width: 100%;
    height: 110px;
    border: 1px solid #dddddd;
    border-radius: 20px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0px;

    .header {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      h4 {
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Montserrat-SemiBold";
        margin: 0px;
      }

      span {
        width: 70%;
        font-size: 12px;
        color: #979797;
        text-align: right;
      }
    }

    .colorOptions {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
      margin: 0 auto;

      .options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .color {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          transition: width 0.2s ease-in-out;
          border-radius: 2px;

          .arrowIcon {
            position: absolute;
            top: -25px;
            left: 35%;
          }

          span {
            width: 95%;
            color: #fff;
            font-size: 12px;
            word-break: break-all;
            text-align: center;
          }
        }
      }
    }
  }
  .submitBtn {
    margin: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1300px) {
    width: 95%;
  }

  @media screen and (max-width: 1180px) {
    width: 98%;

    .item {
      height: 120px;
      flex-direction: column;
      align-items: unset;

      .options {
        margin: 0px auto !important;

        .color {
          margin-top: 10px;
          .arrowIcon {
            left: 38% !important;

            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .colorOptions {
        width: 100%;
        .options {
          width: 90%;
        }
      }
      .mobileLabels {
        display: flex;
        justify-content: space-between;
        margin: 10px 20px;
        font-size: 12px;
        font-family: "Montserrat-Medium";
      }
    }
  }
}
