.container {
  width: 80%;
  max-width: 900px;
  margin: 10px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 22px;
    font-family: "Montserrat-SemiBold";
  }

  .description {
    color: #979797;
    font-size: 14px;
  }

  .label {
    width: 160px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-Medium";
    font-size: 12px;

    span {
      padding: 20px 0px;
    }
  }

  .bold {
    font-family: "Montserrat-SemiBold";
    font-size: 11px;
  }

  .content {
    margin: 20px 0px 0px 20px;
  }

  .card {
    width: 180px;
    height: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    font-size: 11px;
    border: 1px solid #dde3eb;

    .topsection,
    .ratings {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-top: 10px;
    }

    .ratings {
      margin-top: 10px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        &:nth-child(4) {
          border: 2px solid #ff6333;
          border-radius: 50%;
          padding: 2px;
        }
      }

      .options {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }
  }

  .defaultRow {
    width: 90%;
    display: flex;
    align-items: center;
    margin: 0 auto;

    .productCard {
      width: 180px;
      height: 122px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      color: #000;
      box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
      font-size: 11px;
      border: 1px solid #dde3eb;

      .imageContent {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 25px;
          height: 105px;
          object-fit: cover;
        }
      }

      .rightContent {
        width: 70%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;

        div {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .secondRow {
    width: 90%;
    display: flex;
    align-items: center;
    margin: -30px auto 0px 0px;

    .label {
      height: 100px;
      justify-content: flex-end;
      margin-bottom: 10px;

      svg {
        margin-bottom: 10px;
      }
      span {
        padding: 5px;
      }
    }
  }

  .thirdRow {
    margin-top: -10px;

    .card {
      width: 160px;
    }

    .top {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      .mainBtn {
        width: 129px;
        height: 10px;
        background-color: var(--color);
        color: #fff;
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 5px;

        &:hover {
          opacity: 0.9;
        }
      }

      .deleteWineIcon {
        border: 1px solid #000;
        border-radius: 4px;
        padding: 2px 5px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
