.detailsWrapper {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actionItems {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .productRating {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }

      .bookmarkIconWeb {
        cursor: pointer;
      }

      .addToCartButton {
        width: 48px;
        height: 48px;
        background-color: var(--color);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bookmarkIconWeb {
          margin-right: 20px;
        }
      }
    }
  }

  .mainInfo {
    display: flex;
    justify-content: space-around;
    margin: 50px 0px;
    height: 430px;

    .leftPart {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      height: 80%;

      .middleLabels {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div {
          &:nth-child(2) {
            font-family: "Montserrat-Bold";
          }

          &:last-child {
            font-size: 14px;
          }
        }
      }

      .productRating {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 5px;
        }
      }

      .producerLabel {
        color: var(--color);
        text-align: center;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Medium";
        font-size: 18px;
        font-style: normal;
        line-height: 20px;
        text-transform: uppercase;
        margin-top: 20px;
      }

      .wineNameLabel {
        color: #494949;
        text-align: center;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Bold";
        font-size: 18px;
        font-style: normal;
        line-height: 22px;
      }

      .subtitleLabel {
        color: #494949;
        text-align: center;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Regular";
        font-size: 18px;
        font-style: normal;
        line-height: 22px;
      }
    }

    .rightPart {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 220px;
        height: 430px;
        object-fit: contain;
      }
    }
  }
  .priceLabel,
  .mobilePriceBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color);
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "Montserrat-SemiBold";
    font-size: 25px;
    font-style: normal;
    line-height: 16px;

    .discount {
      color: #ff6333;
      font-size: 12px;
      text-decoration: line-through;
    }
  }

  .actionsContent {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
    padding: 20px 0px;
    min-height: 60px;

    .mobilePriceBox {
      align-items: center;
      width: 90%;
    }
    .addToCartButton {
      width: 48px;
      height: 48px;
      background-color: var(--color);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .labels {
    margin-left: 10px;
  }
  .noteText {
    position: relative;
    max-width: 95%;
    margin: 50px auto;
    text-align: justify;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:first-child {
      margin: 0 auto !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: -8px;
      background-color: var(--color);
    }

    .verticalText {
      transform-origin: left top 0;
      font-size: 16px;
      white-space: nowrap;
      text-transform: uppercase;
      cursor: vertical-text;
      position: absolute;
      display: block;
      height: auto;
      direction: rtl;
      left: -36px;
      z-index: 11;
      bottom: 0px;
      color: var(--color);
    }

    .data {
      display: flex;
      justify-content: space-between;
      max-width: 95%;
      text-align: left;

      .title {
        color: #000;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: 25.511px;
      }

      div {
        &:first-child {
          width: 70%;

          div,
          span {
            width: 85%;
          }
        }

        &:last-child {
          width: 30%;
          min-width: 170px;

          div,
          span {
            width: 100%;
          }
        }
      }
    }

    .varietal {
      .title {
        color: #000;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: 25.511px;
      }
    }
  }

  .deliveryDetails {
    color: var(--color);
    font-family: "Montserrat-Regular";
    font-size: 12px;
    line-height: 23.5px;
  }

  .bookmarkIcon {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  @media screen and (max-width: 1180px) {
    padding: 0px;
    margin: 0px auto 100px auto;

    .header {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 120px;
      z-index: 11;
      right: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .actionItems {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bottleUnit {
          display: flex;
          align-items: center;
          padding: 0px 20px;
        }

        .unitSelection {
          padding: 6px 16px;
          color: var(--color);
          text-align: center;
          font-family: "Montserrat-Bold";
          font-size: 12px;
          font-style: normal;
          line-height: 20px;
          text-transform: capitalize;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-right: 34px;

          span {
            margin-right: 10px;
          }
        }
        .quantity {
          width: 100px;
          height: 40px;
          color: #fff;
          text-align: center;
          font-family: "Montserrat-Regular";
          font-size: 18px;
          font-style: normal;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color);
        }
        .addToCartBtn {
          width: 200px;
          background-color: var(--color);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            height: 18px;
            margin-left: 10px;
          }
        }
      }
      .mobilePrice {
        color: #000;
        text-align: center;
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        font-style: normal;
        line-height: 20px;
        margin: 10px 5% 20px 0px;
        text-align: left;

        .discount {
          font-size: 12px;
          color: #ff6333;
          text-decoration: line-through;
        }
      }
    }
    .mainInfo {
      margin-top: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: unset;

      .leftPart {
        width: 80%;

        .middleLabels {
          margin-top: 20px;
          height: 100px;
        }
        .wineNameLabel {
          margin: 10px 0px;
        }
      }
      .rightPart {
        margin: 20px 0px;
        text-align: center;

        img {
          height: 260px;
        }

        .badges {
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 auto;
        }
        .heroText {
          margin: 10px 0px;
        }
      }
    }

    .labels {
      padding: 0px 30px;
    }
    .deliveryDetails {
      padding: 0px 20px;
    }

    .noteText {
      .data {
        max-width: 100%;
        text-align: unset !important;
        flex-direction: column;

        div {
          width: 100% !important;
        }
      }
    }
  }
}

.header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;

  .bookmarkIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
}
