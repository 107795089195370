.wrapper {
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .card {
    color: #fff;
    max-width: 800px;
    width: 100%;
    height: 800px;
    margin: 30px auto 0px auto;
    border-radius: 32px;
    background: rgb(29, 3, 54);
    background: -moz-linear-gradient(
      129deg,
      rgba(29, 3, 54, 1) 0%,
      rgba(84, 10, 156, 1) 84%
    );
    background: -webkit-linear-gradient(
      129deg,
      rgba(29, 3, 54, 1) 0%,
      rgba(84, 10, 156, 1) 84%
    );
    background: linear-gradient(
      129deg,
      rgba(29, 3, 54, 1) 0%,
      rgba(84, 10, 156, 1) 84%
    );

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px;

      .closeIcon {
        cursor: pointer;
      }

      h3 {
        color: #ff6333;
        font-size: 20px;
        margin: 0px;
      }
    }

    .content {
      width: 85%;
      margin: 0 auto;

      h2 {
        font-family: "Montserrat-SemiBold";
        font-size: 20px;
        margin-top: 50px;

        b {
          color: #fe730d;
        }
      }

      .description {
        font-size: 14px;
        color: #979797;
      }

      .selectOptions {
        width: 330px;
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto 0px auto;

        .option {
          position: relative;

          .removeOption {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #511c85;
            border-radius: 50%;
            position: absolute;
            top: -10px;
            right: 0px;
            font-size: 22px;
          }

          &:hover {
            background-color: #fff9d8;
            color: #fe730d;
            border-color: #fe730d;
          }
        }

        .manualOption {
          &:hover {
            opacity: 0.6;
          }
        }

        .option,
        .manualOption {
          width: 150px;
          height: 50px;
          border: 2px solid #fff;
          border-radius: 24px;
          font-size: 14px;
          font-family: "Montserrat-SemiBold";
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          margin: 5px;
        }

        .noSelected {
          background-color: #fff9d8;
          color: #fe730d;
          border-color: #fe730d;
        }
        .yesSelected {
          background-color: #fff9d8;
          color: #49bd36;
          border-color: #49bd36;
        }
      }

      span {
        font-size: 16px;
      }

      button {
        background-color: #fff !important;
        color: #000 !important;
        margin: 50px auto !important;
      }
    }

    .mainButton {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        background-color: #fff !important;
        color: #000 !important;
        margin: 50px auto !important;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;

    .card {
      width: 90%;
      height: 780px;
      margin: 30px auto;

      .selectOptions {
        width: 100%;

        .option,
        .manualOption {
          width: 125px !important;
        }

        .option {
          &:hover {
            background-color: transparent;
            color: #fff;
          }
        }
      }
    }
  }
}

.addFieldContainer {
  background-color: #511c85;
  height: 300px;
  border-radius: 12px;
  color: #fff;

  .popupHeader {
    padding: 10px;

    .closeIcon {
      width: 24px;
      cursor: pointer;
    }
  }

  .content {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40%;

      input {
        width: 150px;
        height: 48px;
        outline: none;
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 24px;
        padding: 0px 10px;
      }
    }
    span {
      font-size: 14px;
    }

    button {
      width: 150px;
      background-color: #fff !important;
      color: #000 !important;
    }
  }
}
