.wrapper {
  max-width: 50%;
  text-align: center;
  margin: 0 auto;

  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    top: 0;
    position: sticky;
    z-index: 999;
    padding-top: 10px;

    .backButtonContainer {
      display: flex;
      align-items: center;

      .pointer {
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .rightLabel {
      font-size: 16px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .questionHeader {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h3 {
        text-align: left;
        font-size: 1.25rem;
        font-family: "Montserrat-SemiBold";
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 0px;
      }

      span {
        max-width: 70%;
        font-size: 12px;
        color: #979797;
        text-align: right;
      }
    }

    .options {
      width: 90%;
      height: 370px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      margin: 20px auto;
      border: 1px solid #b7bdc0;
      border-radius: 12px;

      .option {
        height: 40px;
        border: 2px solid transparent;
        border-radius: 15px;
        cursor: pointer;
        font-family: "Montserrat-Medium";
        font-size: 14px;
        text-align: left;
        transition: all 0.3s ease;
        margin: 10px auto;
        padding: 0px 20px;
        font-size: 14px;

        &:hover {
          opacity: 0.8;
          border-color: var(--color);
        }
      }

      .selectedOption {
        border: 2px solid #000;
      }
    }

    .bottom {
      margin: 0px auto 40px auto;
      button {
        margin-top: 20px;
      }
    }
  }

  .submitContent {
    max-width: 300px;
    height: 300px;
    margin: 0 auto;
    h2 {
      color: #000;
    }
  }

  h2 {
    color: var(--color);
    font-size: 18px;
    font-family: "Montserrat-SemiBold";
    margin: 20px 0px 10px 0px;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 5%;

    button {
      padding: 12px 30px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      font-family: "Montserrat-Medium";
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;
  }
}
